.backgroundcontainer {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
}

.background-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
