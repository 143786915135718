.nav {
  width: 100vw;
  height: 15vh;
  border-bottom: 2px solid white;
  border-top: 2px solid white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: antiquewhite;
  animation: fadein 0.5s;
}

.navheading {
  height: 10vh;
  width: 10vh;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 850px) {
  .nav {
    height: 10vh;
  }
}
