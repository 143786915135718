.container {
  position: fixed;
  min-height: 85vh;
  width: 100vw;
  max-width: 1500px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  pointer-events: none;
}

.mainbox {
  display: flex;
  align-items: center;
  position: absolute;
  color: aliceblue;
  margin: auto;
  border-radius: 5%;
  border: 1px solid white;
  box-sizing: border-box;
  z-index: 3;
  pointer-events: all;
}

.mainbox:hover {
  background: radial-gradient(#000000 0%, #164949 0%, #ffffff00 100%);
}

.heading {
  font-size: xx-large;
  font-weight: 700;
  padding: 1vh;
}

.selectionbuttons {
  position: absolute;
  top: 70vh;
  width: 96vw;
}

.summaryline {
  font-size: xx-large;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

@keyframes fillscreen {
  0% {
    width: 30vw;
    height: 50vh;
  }
  100% {
    width: 96vw;
    height: 75vh;
    left: 2vw;
  }
}

@keyframes collapsescreen {
  0% {
    width: 96vw;
    height: 75vh;
    left: 2vw;
  }
  100% {
    width: 30vw;
    height: 50vh;
  }
}

@media only screen and (max-width: 850px) {
  .mainbox {
    font-size: medium;
  }
  .heading {
    font-size: large;
  }
  .summaryline {
    font-size: large;
  }
}
