.projectbox:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 20vw;
  height: 20vh;
  border-radius: 5%;
  border: 1px solid white;
  overflow: hidden;
  font-size: x-small;
  opacity: 0.5;
  animation: moveleft;
}

.projectbox:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 40vw;
  height: 55vh;
  border-radius: 5%;
  border: 1px solid white;
  overflow: auto;
  font-size: large;
  animation: minimise;
}

.projectbox:nth-of-type(3) {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 20vw;
  height: 20vh;
  border-radius: 5%;
  border: 1px solid white;
  overflow: hidden;
  font-size: x-small;
  opacity: 0.5;
  animation-name: maximise;
}
.projectbox:nth-of-type(4) {
  position: absolute;
  display: block;
  margin: auto;
  width: 20vw;
  height: 20vh;
  border-radius: 5%;
  border: 1px solid white;
  overflow: hidden;
  font-size: x-small;
  opacity: 0.5;
  animation: moveleft;
  left: 120vw;
}

.projectbox {
  display: none;
}

.projectscontainer {
  display: flex;
  flex-direction: row;
  animation: fadein 1s;
}

.nextbutton {
  position: absolute;
  top: 64vh;
  width: 96vw;
}

@keyframes moveleft {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(-47vw, 17.5vh);
  }
}

@keyframes smallscreenmove {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(-90vw);
    font-size: large;
    opacity: 1;
  }
}

@keyframes minimise {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(-25.25vw);
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 20vw;
    height: 20vh;
    border-radius: 5%;
    border: 1px solid white;
    overflow: hidden;
    font-size: x-small;
    opacity: 0.5;
  }
}

@keyframes maximise {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(-25.25vw);
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 40vw;
    height: 55vh;
    border-radius: 5%;
    border: 1px solid white;
    overflow: auto;
    opacity: 1;
    font-size: large;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 850px) {
  .projectbox:nth-of-type(1) {
    display: none;
  }
  .projectbox:nth-of-type(2) {
    width: 75vw;
    animation: smallscreenmove;
  }
  .projectbox:nth-of-type(3) {
    position: absolute;
    left: 100vw;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 75vw;
    height: 55vh;
    border-radius: 5%;
    border: 1px solid white;
    overflow: auto;
    animation: smallscreenmove;
  }
  .projectbox:nth-of-type(4) {
    display: none;
  }
}
