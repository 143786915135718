.logo {
  display: flex;
  margin: auto;
  height: 100px;
  padding-top: 10px;
}

.projectinfo {
  font-size: large;
  display: block;
}

.list {
  margin: auto;
}

li {
  text-align: left;
  padding-left: 1rem;
}

h2 {
  margin-bottom: 5px;
}

@media only screen and (max-width: 850px) {
  .projectinfocontainer {
    display: flex;
    flex-direction: column;
  }
  .keyelementslist {
    display: block;
  }
}
