.skills-container {
  display: flex;
  margin: auto;
  flex-direction: row;
  justify-content: center;
  font-size: 20px;
  height: 60vh;
  width: 90vw;
  overflow-y: scroll;
  overflow-wrap: break-word;
  animation: fadein 1s;
}

.programming-skills {
  width: 30vw;
  margin-left: auto;
  margin-right: auto;
}

h2 {
  margin-bottom: 20px;
}

li {
  padding-left: 2em;
  text-indent: -1.5em;
}

@media only screen and (max-width: 1000px) {
  .skills-container {
    flex-direction: column;
    justify-content: flex-start;
  }
  .programming-skills {
    width: 90vw;
  }
  h2 {
    font-size: x-large;
  }
}
