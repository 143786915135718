.experience-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 0 auto;
  font-size: 20px;
  height: 60vh;
  width: 90vw;
  overflow-y: scroll;
  overflow-wrap: break-word;
  animation: fadein 1s;
}


.personal-qualities {
  top: 30vh;
  min-width: 40vw;
}

h2 {
  margin-bottom: 20px;
}

li {
  padding-left: 2em;
  text-indent: -1.5em;
}

@media only screen and (max-width: 1000px) {
  .experience-container {
    flex-direction: column;
    justify-content: flex-start;
  }
  .personal-qualities {
    min-width: none;
  }
  h2 {
    font-size: x-large;
  }
}
