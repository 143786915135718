.App {
  overflow: hidden;
  position: absolute;
  text-align: center;
  height: auto;
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  background-color: black;
}

button {
  border: 1px solid white;
  border-radius: 8px;
  box-sizing: border-box;
  color: white;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  outline: none;
  padding: 5px 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s,
    transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

button:active {
  transform: scale(0.96);
}
